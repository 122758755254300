import React from "react";
import { Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";

import Main from "../pages/Main";
import Works from "../pages/Works";
import Services from "../pages/Services";
import Agency from "../pages/Agency";
import Contact from "../pages/Contact";
import Career from "../pages/Career";
import Soul from "../pages/Works/Jobs/Soul";
import Orthopride from "../pages/Works/Jobs/Orthopride";
import Seleto from "../pages/Works/Jobs/Seleto";
import Unimed30 from "../pages/Works/Jobs/Unimed30";
import UnimedPedala from "../pages/Works/Jobs/UnimedPedala";
import Unimed from "../pages/Works/Jobs/Unimed";
import SiderNatal from "../pages/Works/Jobs/SiderNatal";
import Frilat from "../pages/Works/Jobs/Frilat";
import Toca from "../pages/Works/Jobs/Toca";
import OrthoprideApp from "../pages/Works/Jobs/OrthoprideApp";
import WebCol from "../pages/Works/Jobs/WebCol";
import CortevaApp from "../pages/Works/Jobs/CortevaApp";
import Interligados from "../pages/Works/Jobs/Interligados";

export default function Routes({ history }) {
  ReactGA.pageview(history.location.pathname);
  return (
    <Switch>
      <Route path="/" exact component={Main} />
      <Route path="/agencia" exact component={Agency} />
      <Route path="/trabalhos" exact component={Works} />
      <Route path="/trabalhos/soul" exact component={Soul} />
      <Route path="/trabalhos/orthopride" exact component={Orthopride} />
      <Route path="/trabalhos/seleto" exact component={Seleto} />
      <Route path="/trabalhos/unimed-30-anos" exact component={Unimed30} />
      <Route path="/trabalhos/unimed" exact component={Unimed} />
      <Route path="/trabalhos/unimed-pedalada" exact component={UnimedPedala} />
      <Route
        path="/trabalhos/sider-shopping-natal"
        exact
        component={SiderNatal}
      />
      <Route path="/trabalhos/frilat" exact component={Frilat} />
      <Route path="/trabalhos/toca-do-pintado" exact component={Toca} />
      <Route path="/trabalhos/orthopride-app" exact component={OrthoprideApp} />
      <Route path="/trabalhos/webcol" exact component={WebCol} />
      <Route
        path="/trabalhos/business-intelligence"
        exact
        component={CortevaApp}
      />
      <Route path="/trabalhos/interligados" exact component={Interligados} />
      <Route path="/servicos" exact component={Services} />
      <Route path="/contato" exact component={Contact} />
      <Route path="/vem-pra-foco" exact component={Career} />
    </Switch>
  );
}
