import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import {
  Container,
  Content,
  JobHeader,
  ContentHeader,
  ContentJob,
  Center,
  Wrap
} from "./styles";

import bgHeader from "../../../../assets/works/tocadopintado/bg-header.png";
import social1 from "../../../../assets/works/tocadopintado/social1.png";
import social2 from "../../../../assets/works/tocadopintado/social2.png";
import social3 from "../../../../assets/works/tocadopintado/social3.png";
import social4 from "../../../../assets/works/tocadopintado/social4.png";
import social5 from "../../../../assets/works/tocadopintado/social5.png";
import social6 from "../../../../assets/works/tocadopintado/social6.png";
import social7 from "../../../../assets/works/tocadopintado/social7.png";
import social8 from "../../../../assets/works/tocadopintado/social8.png";

import WOW from "wowjs";

export default class Toca extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <Container>
        <Header color={"#FFF"} colorVem={"#3ADE59"} />
        <Content>
          <JobHeader bg={bgHeader}>
            <ContentHeader>
              <h1 className="wow fadeInDown" data-wow-delay="100ms">
                Toca do Pintado
              </h1>
            </ContentHeader>
          </JobHeader>
          <ContentJob>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="150ms">
                A Toca do Pintado
              </h2>
              <Wrap className="wow fadeIn" data-wow-delay="200ms">
                <p>
                  Sabor, qualidade e tradição. Esses são os pilares para a
                  comunicação da Toca do Pintado. Restaurante tradicional em
                  peixes, na região Sul Fluminense, com o seu Pintado na Brasa.
                </p>
                <Link to="/trabalhos">
                  <i className="fa fa-long-arrow-left" aria-hidden="true" />
                  Voltar
                </Link>
              </Wrap>
              <h3 className="wow fadeIn solo" data-wow-delay="150ms">
                Posts
              </h3>
            </Center>
            <Center>
              <Wrap posts>
                <img
                  src={social1}
                  alt="Cartaz Pedalada"
                  className="social wow fadeIn"
                  data-wow-delay="150ms"
                />
                <img
                  src={social2}
                  alt="Cartaz Pedalada"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
                <img
                  src={social3}
                  alt="Cartaz Pedalada"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
                <img
                  src={social4}
                  alt="Cartaz Pedalada"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
                <img
                  src={social5}
                  alt="Cartaz Pedalada"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
                <img
                  src={social6}
                  alt="Cartaz Pedalada"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
                <img
                  src={social7}
                  alt="Cartaz Pedalada"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
                <img
                  src={social8}
                  alt="Cartaz Pedalada"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
              </Wrap>
            </Center>
          </ContentJob>
          <Footer />
        </Content>
      </Container>
    );
  }
}
