import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import {
  Container,
  Content,
  JobHeader,
  ContentHeader,
  ContentJob,
  Center,
  Wrap,
  Site
} from "./styles";

import bgHeader from "../../../../assets/works/orthopride/bg-header.png";
import mascote from "../../../../assets/works/orthopride/mascote.png";
import cartaz from "../../../../assets/works/orthopride/cartaz.png";
import siteMobile from "../../../../assets/works/orthopride/site-mobile.png";

import WOW from "wowjs";

export default class Orthopride extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <Container>
        <Header color={"#FFF"} colorVem={"#000"} />
        <Content>
          <JobHeader bg={bgHeader}>
            <ContentHeader>
              <h1 className="wow fadeInDown" data-wow-delay="100ms">
                Orthopride
              </h1>
            </ContentHeader>
          </JobHeader>
          <ContentJob>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="150ms">
                A Orthopride
              </h2>
              <Wrap className="wow fadeIn" data-wow-delay="200ms">
                <p>
                  Hoje são mais de 100 unidades em todo o Brasil e a Foco está
                  presente desde a inauguração de sua primeira clínica. A
                  Orthopride investe na sua marca e ja teve como garoto
                  propaganda o ator Bruno Gagliasso, hoje quem representa a
                  marca é o ator Caio Castro. A marca também investe no esporte
                  e hoje patrocina o bicampeão mundial de surf Gabriel Medina, o
                  piloto da Stock Car, Rafael Suzuki, o Shooto Brasil, maior
                  franquia de MMA do Brasil. No futebol a Orthopride patrocina o
                  Corinthians, Santos, Botafogo, Voltaço e o Maracanã.
                </p>
                <Link to="/trabalhos">
                  <i className="fa fa-long-arrow-left" aria-hidden="true" />
                  Voltar
                </Link>
              </Wrap>
              <h3 className="wow fadeIn" data-wow-delay="150ms">
                Vídeo Franchising
              </h3>
              <div className="video wow fadeIn" data-wow-delay="200ms">
                <iframe
                  src="https://player.vimeo.com/video/257943778?loop=1&color=ff9933&title=0&byline=0&portrait=0"
                  className="iFrame"
                  frameborder="0"
                  allow="autoplay; fullscreen"
                  allowfullscreen
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
              <h3 className="wow fadeIn" data-wow-delay="150ms">
                Conheça o Thop, mascote da marca
              </h3>
              <p className="wow fadeIn" data-wow-delay="200ms">
                O mascote surgiu da necessidade de um personagem que conversasse
                com seu público e transmitisse os princípios da marca, como
                felicidade, jovialidade e espirito positivo.
              </p>
            </Center>
            <img
              src={mascote}
              alt="Mascote Orthopride"
              className="full wow fadeIn"
              data-wow-delay="200ms"
            />
            <Center className="wow fadeIn" data-wow-delay="150ms">
              <h3 className="solo">Peças of-line</h3>
            </Center>
            <img
              src={cartaz}
              alt="Impressos"
              className="full wow fadeIn"
              data-wow-delay="200ms"
            />
            <Site>
              <Center>
                <img
                  src={siteMobile}
                  alt="Site"
                  className="phone wow fadeInLeft"
                  data-wow-delay="200ms"
                />
                <Wrap className="right">
                  <Wrap
                    className="column wow fadeIn last"
                    data-wow-delay="200ms"
                  >
                    <h3>Site</h3>
                    <p>
                      O site foi desenvolvido com foco na captação de leads e
                      novos franqueados. Utilizamos o conceito "Mobile First",
                      garantindo uma experiência mais completa, informativa e
                      agradável para a grande parte dos usuário.
                    </p>
                  </Wrap>
                </Wrap>
              </Center>
            </Site>
          </ContentJob>
          <Footer />
        </Content>
      </Container>
    );
  }
}
