import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import {
  Container,
  Content,
  JobHeader,
  ContentHeader,
  ContentJob,
  Center,
  Wrap
} from "./styles";

import bgHeader from "../../../../assets/works/unimedpedala/bg-header.png";
import camisa from "../../../../assets/works/unimedpedala/camisa.png";
import cartaz1 from "../../../../assets/works/unimedpedala/cartaz-1.png";
import cartaz2 from "../../../../assets/works/unimedpedala/cartaz-2.png";
import leque from "../../../../assets/works/unimedpedala/leque.png";

import WOW from "wowjs";

export default class UnimedPedala extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <Container>
        <Header color={"#FFF"} colorVem={"#3ADE59"} />
        <Content>
          <JobHeader bg={bgHeader}>
            <ContentHeader>
              <h1 className="wow fadeInDown" data-wow-delay="100ms">
                Unimed <br />
                Volta Redonda
              </h1>
            </ContentHeader>
          </JobHeader>
          <ContentJob>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="150ms">
                Pedalada Costazul
              </h2>
              <Wrap className="wow fadeIn" data-wow-delay="200ms">
                <p>
                  Com o intuito de promover saúde e bem-estar, a
                  recém-inaugurada Unimed Volta Redonda - Unidade Litoral,
                  participou da Pedalada promovida pela rádio Costazul. Um
                  evento para toda a família, na cidade de Angra dos Reis/RJ.
                </p>
                <Link to="/trabalhos">
                  <i className="fa fa-long-arrow-left" aria-hidden="true" />
                  Voltar
                </Link>
              </Wrap>
              <Wrap>
                <img
                  src={cartaz1}
                  alt="Cartaz Pedalada"
                  className="wow fadeIn"
                  data-wow-delay="150ms"
                />
                <img
                  src={cartaz2}
                  alt="Cartaz Pedalada"
                  className="wow fadeIn"
                  data-wow-delay="200ms"
                />
              </Wrap>

              <h3 className="wow fadeIn solo" data-wow-delay="150ms">
                Materiais de apoio
              </h3>
            </Center>
            <img
              src={camisa}
              alt="Mascote Orthopride"
              className="full wow fadeIn"
              data-wow-delay="200ms"
            />
            <img
              src={leque}
              alt="Mascote Orthopride"
              className="full wow fadeIn"
              data-wow-delay="200ms"
            />
          </ContentJob>
          <Footer />
        </Content>
      </Container>
    );
  }
}
