import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import {
  Container,
  Content,
  JobHeader,
  ContentHeader,
  ContentJob,
  Wrap
} from "./styles";

import bgHeader from "../../../../assets/works/soul/bg-header.png";
import Item1 from "../../../../assets/works/soul/item-1.png";
import Item2 from "../../../../assets/works/soul/item-2.png";
import Item3 from "../../../../assets/works/soul/item-3.png";
import Item4 from "../../../../assets/works/soul/item-4.png";
import Item5 from "../../../../assets/works/soul/item-5.png";

import WOW from "wowjs";

export default class Soul extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <Container>
        <Header color={"#FFF"} colorVem={"#3ADE59"} />
        <Content>
          <JobHeader bg={bgHeader}>
            <ContentHeader>
              <h1 className="wow fadeInDown" data-wow-delay="100ms">
                <span>Soul</span>
                <br />
                Make & Hair
              </h1>
            </ContentHeader>
          </JobHeader>
          <ContentJob>
            <h2 className="wow fadeIn" data-wow-delay="100ms">
              Identidade Visual
            </h2>
            <Wrap className="wow fadeIn" data-wow-delay="150ms">
              <p>
                O JOB desenvolvido para a empresa se deu desde a definição do
                nome, passando pela construção da marca e o desenvolvimento das
                estratégias de comunicação.
              </p>
              <Link to="/trabalhos">
                <i className="fa fa-long-arrow-left" aria-hidden="true" />
                Voltar
              </Link>
            </Wrap>
            <img
              src={Item1}
              alt="Soul"
              className="wow fadeIn"
              data-wow-delay="200ms"
            />
            <img
              src={Item2}
              alt="Soul"
              className="wow fadeIn"
              data-wow-delay="200ms"
            />
            <img
              src={Item3}
              alt="Soul"
              className="wow fadeIn"
              data-wow-delay="200ms"
            />
            <img
              src={Item4}
              alt="Soul"
              className="wow fadeIn"
              data-wow-delay="200ms"
            />
            <img
              src={Item5}
              alt="Soul"
              className="wow fadeIn"
              data-wow-delay="200ms"
            />
          </ContentJob>
          <Footer />
        </Content>
      </Container>
    );
  }
}
