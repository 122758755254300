import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #0d4e3b;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 80px 40px;
  padding-right: 0;
  align-items: center;
  @media screen and (max-width: 980px) {
    flex-direction: column;
    padding: 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1280px;

  p.error {
    flex: 1;
    text-align: center;
  }

  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: max-content;
  padding-bottom: 40px;
  margin-right: 24px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);

  @media screen and (max-width: 980px) {
    width: 100%;
  }

  a,
  p {
    display: block;
    text-decoration: none;
    font-size: 16px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    transition: 400ms ease;
    span {
      color: #51ce69 !important;
    }

    &:hover {
      color: #51ce69;
    }
  }
`;
export const Title = styled.h3`
  font-size: 40px;
  color: #fff;
  span {
    color: #51ce69;
  }
`;

export const Fotos = styled.ul`
  display: flex;
  flex: 1;
  overflow: hidden;
  width: 100%;
  @media screen and (max-width: 980px) {
    width: 100%;
  }

  .gallery {
    width: 100% !important;
    height: auto !important;
  }
`;

export const Foto = styled.li`
  float: left;
  width: 100%;
  max-width: 290px;
  background: black;

  img {
    width: 290px;
    height: 290px;
    transition: all 0.3s ease;
    display: block;
    object-fit: cover;
  }

  @media screen and (max-width: 640px) {
    margin: 24px 0 0 0;
    max-width: 100%;

    img {
      width: 100% !important;
      height: auto !important;
    }
  }
`;
