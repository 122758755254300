import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const JobHeader = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  height: 768px;
  ${props => props.bg && `background: url(${props.bg}) no-repeat center top`}
  background-attachment: fixed;

  @media screen and (max-width: 980px) {
    background-position-x: center;
  }
`;

export const ContentHeader = styled.div`
  width: 980px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  img.logoUnimed {
    margin-bottom: 32px;
  }

  h1 {
    color: #fff;
    font-size: 76px;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -4px;
    margin-bottom: 24px;
  }

  p {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 22px;
  }

  @media screen and (max-width: 980px) {
    width: 80%;
  }

  @media screen and (max-width: 414px) {
    padding-bottom: 48px;
    h1 {
      font-size: 56px;
      line-height: 66px;
      letter-spacing: -2px;
    }
  }
`;

export const ContentJob = styled.section`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 960px) {
    width: 100%;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #777777;
    padding-right: 64px;
  }

  h2 {
    color: #272727;
    margin-bottom: 32px;
  }
`;

export const Center = styled.div`
  width: 960px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 64px 0;
  position: relative;

  img.negative {
    position: absolute;
    left: center;
    bottom: -80px;
  }

  &.final {
    padding-top: 64px;
  }

  p.min {
    font-size: 16px;
    line-height: 24px;
    color: #777;
    max-width: 600px;
    margin-bottom: 48px;
    padding: 0 0 0 64px;
  }
  h2.min {
    padding: 0 0 0 64px;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    padding: 0 32px;

    img.screen {
      width: 100%;
    }

    p.min {
      max-width: 100%;
      width: 100%;
    }
  }
  @media screen and (max-width: 640px) {
    p.min {
      padding: 0 0 0 32px;
    }
    h2.min {
      padding: 0 0 0 32px;
    }
  }
  @media screen and (max-width: 414px) {
    width: 100%;
  }
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 54px;
  position: relative;

  &.wrap {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 0;
  }

  &.right {
    justify-content: flex-end;
  }

  &.column {
    flex-direction: column;
    flex: 0.5;
    @media screen and (max-width: 960px) {
      flex: 0.8;
    }
  }

  a {
    display: flex;
    align-items: center;
    color: #0d4e3b;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-weight: 600;

    i {
      margin-right: 8px;
    }
  }
  @media screen and (max-width: 640px) {
    &.first {
      flex-direction: column-reverse;
    }

    p {
      padding-right: 0;
    }

    a {
      position: absolute;
      right: 0;
      top: -60px;
    }
  }
`;

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  height: 875px;
  ${props => props.bg && `background: url(${props.bg}) no-repeat center top`}

  @media screen and (max-width:980px) {
    background-size: cover;
  }
`;
export const Visual = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  background: #f0efee;
  margin-top: -145px;
`;
