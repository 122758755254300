import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import {
  Container,
  Content,
  JobHeader,
  ContentHeader,
  ContentJob,
  Center,
  Wrap
} from "./styles";

import bgHeader from "../../../../assets/works/cortevaapp/bg-header.png";
import tela1 from "../../../../assets/works/cortevaapp/tela1.png";
import tela2 from "../../../../assets/works/cortevaapp/tela2.png";
import tela3 from "../../../../assets/works/cortevaapp/tela3.png";
import tela4 from "../../../../assets/works/cortevaapp/tela4.png";
import tela5 from "../../../../assets/works/cortevaapp/tela5.png";
import tela6 from "../../../../assets/works/cortevaapp/tela6.png";

import WOW from "wowjs";

export default class CortevaApp extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <Container>
        <Header color={"#FFF"} colorVem={"#3ADE59"} />
        <Content>
          <JobHeader bg={bgHeader}>
            <ContentHeader>
              <h1 className="wow fadeInDown" data-wow-delay="100ms">
                <span>App</span>
                <br />
                Business <br />
                intelligence
              </h1>
            </ContentHeader>
          </JobHeader>
          <ContentJob>
            <Center className="first">
              <h2 className="wow fadeIn" data-wow-delay="150ms">
                Introdução
              </h2>
              <Wrap className="first wow fadeIn" data-wow-delay="200ms">
                <p className="first">
                  A Gera Sinergia é uma empresa responsável por intermediar e
                  tornar mais eficiente as operações de transportes de cargas de
                  seus clientes. Pensando nisso, o app nasceu como uma
                  ferramenta que permite uma gestão consciente das metas e
                  valores envolvidos num fechamento de quarter. A plataforma
                  reúne todos os demonstrativos referentes à captação de
                  receitas e reconhecimento de notas fiscais da empresa.
                  Permitindo que os dirigentes de cada companhia tenha um acesso
                  facilitado aos indicadores de performance e desempenho do
                  negócio.
                </p>
                <Link to="/trabalhos">
                  <i className="fa fa-long-arrow-left" aria-hidden="true" />
                  Voltar
                </Link>
              </Wrap>
              <Wrap className="screen">
                <img
                  src={tela1}
                  alt="Tela"
                  className="screen wow fadeIn"
                  data-wow-delay="200ms"
                />
                <img
                  src={tela2}
                  alt="Tela"
                  className="screen wow fadeIn"
                  data-wow-delay="200ms"
                />
              </Wrap>
            </Center>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="100ms">
                Consulta de Notas Fiscais
              </h2>
              <p>
                O app permite o acompanhamento em tempo real das Notas Fiscais,
                desde o carregamento até a entrega no cliente final.
              </p>
              <Wrap className="screen">
                <img
                  src={tela3}
                  alt="Tela"
                  className="screen wow fadeIn"
                  data-wow-delay="100ms"
                />
                <img
                  src={tela4}
                  alt="Tela"
                  className="screen wow fadeIn"
                  data-wow-delay="100ms"
                />
              </Wrap>
            </Center>
            <Center className="last">
              <h2 className="wow fadeIn" data-wow-delay="100ms">
                Metas
              </h2>
              <p>
                Com o App também é possível acompanhar o desempenho das vendas
                através de diversos indicadores.
              </p>
              <Wrap className="screen">
                <img
                  src={tela5}
                  alt="Tela"
                  className="screen wow fadeIn"
                  data-wow-delay="100ms"
                />
                <img
                  src={tela6}
                  alt="Tela"
                  className="screen wow fadeIn"
                  data-wow-delay="100ms"
                />
              </Wrap>
            </Center>
          </ContentJob>
          <Footer />
        </Content>
      </Container>
    );
  }
}
