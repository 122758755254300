import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 96px;
  position: relative;

  @media screen and (max-width: 980px) {
    padding-top: 0;
  }
`;
export const Content = styled.div`
  width: 950px;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  align-items: center;

  @media screen and (max-width: 980px) {
    width: 100%;
  }

  .grid {
    width: 950px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    align-items: flex-start;

    @media screen and (max-width: 980px) {
      width: 100%;
    }
  }

  .moreWorks {
    display: flex;
    padding: 0 25px;
    background: #0d4e3b;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 16px;
    transition: all 0.4s ease;
    font-weight: 600;
    font-family: "Poppins", sans-serif;

    &:hover {
      background: #001c09;
    }
  }
`;
export const Card = styled.article`
  width: 50%;
  position: relative;
  box-sizing: border-box;
  background: #333;
  transition: all 0.4s ease;
  @media screen and (max-width: 980px) {
    width: 100%;
  }

  &:hover:not(.card-introduction) {
    .figure {
      transform: scale(1.15);
      opacity: 0.7;
    }
    .legend {
      padding-left: 60px;
    }
  }
  &:nth-child(2) {
    margin-top: 60px;
    @media screen and (max-width: 980px) {
      margin-top: 0;
    }
    .legend {
      bottom: initial;
      top: 10%;
      right: 0%;
      left: initial;
    }
  }
  &:nth-child(2n) {
    .legend {
      right: 0%;
      left: initial;
    }
    &:hover {
      .legend {
        padding-right: 60px;
        padding-left: 15px;
      }
    }
  }
  &.card-introduction {
    background: #1d1d1d;
    padding: 70px;
    color: #fff;

    .title {
      font-size: 32px;
      font-weight: 600;
      line-height: 1.2;
    }
    p {
      font-size: 16px;
      color: #ccc;
      margin: 45px 0;
      line-height: 1.6em;
      font-weight: 300;
      max-width: 85%;
      font-family: "Poppins", sans-serif;
    }
    .sobre {
      height: 40px;
      outline: none;
      color: #fff;
      font-size: 12px;
      display: inline-block;
      background: transparent;
      padding: 0 25px;
      line-height: 40px;
      border: 1.2px #abd942 solid;
      transition: all 0.4s ease;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 2px;

      &:hover {
        background-color: #abd942;
      }
    }
  }
  .link {
    width: 100%;
    height: 100%;
    outline: none;
    display: block;
    overflow: hidden;
    position: relative;
  }
  .figure {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
    transition: all 0.4s ease-in-out;
  }
  .legend {
    background: #fff;
    color: black;
    font-size: 16px;
    line-height: 20px;
    padding: 15px;
    position: absolute;
    bottom: 10%;
    left: 0;
    margin: auto;
    transition: all 0.4s 0.6s ease;
    width: auto;
  }
`;
