import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const JobHeader = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  height: 768px;
  ${props => props.bg && `background: url(${props.bg}) no-repeat center top`}
  background-attachment: fixed;

  @media screen and (max-width: 980px) {
    background-position-x: center;
  }
`;

export const ContentHeader = styled.div`
  width: 980px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 100px;

  h1 {
    color: #00e0c1;
    font-size: 76px;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -4px;
    span {
      color: #fff;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 980px) {
    width: 80%;
  }

  @media screen and (max-width: 414px) {
    padding-bottom: 48px;
    h1 {
      font-size: 56px;
      line-height: 66px;
      letter-spacing: -2px;
    }
  }
`;

export const ContentJob = styled.section`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 960px) {
    width: 100%;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #777777;
    padding-right: 64px;
  }

  h2 {
    color: #272727;
    margin-bottom: 32px;
  }
`;

export const Center = styled.div`
  width: 860px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 0 48px 0;
  position: relative;

  p {
    margin-bottom: 48px;
    &.first {
      margin-bottom: 0;
    }
  }

  &.first {
    margin-top: 54px;
  }

  &.last {
    margin-bottom: 96px;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    padding: 0 32px;
  }
  @media screen and (max-width: 414px) {
    width: 100%;
    img.design {
      width: 100%;
    }
  }
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 48px;
  position: relative;

  &.screen {
    margin-bottom: 0;
  }

  a {
    display: flex;
    align-items: center;
    color: #0d4e3b;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-left: 16px;

    i {
      margin-right: 8px;
    }
  }
  @media screen and (max-width: 640px) {
    &.first {
      flex-direction: column-reverse;
    }

    flex-wrap: wrap;
    justify-content: center;

    p {
      padding-right: 0;
    }

    a {
      position: absolute;
      right: 0;
      top: -60px;
    }
  }
`;
