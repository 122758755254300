import React from "react";
import { Link } from "react-router-dom";

import {
  Container,
  Content,
  About,
  Logo,
  SiteMap,
  Menu,
  Contact,
  Phones,
  Email,
  Social
} from "./styles";

import logo from "../../assets/images/logo-footer.png";
export default function Footer() {
  return (
    <Container>
      <Content>
        <About>
          <Logo>
            <img src={logo} alt="Foco voe mais alto" />
          </Logo>
          <p>Temos pressa, somos interativos e buscamos resultados.</p>
        </About>
        <SiteMap>
          <Menu>
            <Link to="/agencia">Agência</Link>
            <Link to="/trabalhos">Trabalhos</Link>
            <Link to="/servicos">Serviços</Link>
            <Link to="/contato">Contato</Link>
            <Link to="/vem-pra-foco">#vemprafoco</Link>
          </Menu>
          <Contact>
            <Phones>
              <p>
                <i className="fa fa-phone" /> 24 3345-0549
              </p>
              <p>
                <i className="fa fa-phone" /> 21 4063-6334
              </p>
            </Phones>
            <Email>
              <i className="fa fa-envelope" />
              contato@fococomunicacao.com
            </Email>
          </Contact>
        </SiteMap>
        <Social>
          <a
            href="https://www.facebook.com/FocoComunicacao/"
            className="fa fa-facebook"
            target="_blank"
          />
          <a
            href="https://www.instagram.com/agenciafoco/"
            className="fa fa-instagram"
            target="_blank"
          />
        </Social>
      </Content>
    </Container>
  );
}
