import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 30px;
  background: #f7f7f7;
`;
export const Content = styled.div`
  width: 1120px;
  @media screen and (max-width: 980px) {
    width: 100%;
  }
`;
export const Title = styled.h3`
  font-size: 31px;
  font-weight: 600;
  color: #272727;
  text-align: center;
  span {
    color: #0d4e3b;
  }
`;
export const List = styled.ul`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
`;
export const Item = styled.li`
  width: 33%;
  display: flex;

  padding: 0 25px;
  transition: all 0.8s 0.3s ease;
  position: relative;

  @media screen and (max-width: 980px) {
    width: 100%;
    margin-bottom: 32px;
  }

  i {
    font-size: 18px;
    margin-right: 15px;
    color: #59bf61;
    margin-top: 5px;
  }
  div.text {
    display: flex;
    flex-direction: column;

    h4 {
      display: block;
      min-height: 70px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      margin: 0 0 20px;
      color: #202020;

      @media screen and (max-width: 980px) {
        min-height: auto;
      }
    }
    span {
      font-family: "Poppins", sans-serif;
      display: block;
      margin: 10px 0;
      font-size: 15px;
      text-transform: uppercase;
      margin-bottom: 25px;
    }
    p {
      font-size: 15px;
      line-height: 1.5em;
      color: #717171;
    }
  }
`;
