import styled from "styled-components";
import iconMenu from "../../assets/images/icon-menu.svg";

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  transition: height 400ms ease;
  ${props => props.bg && `background: #1E2623`};

  @media only screen and (max-width: 980px) {
    background: #1e2623;
  }
`;
export const Content = styled.div`
  display: flex;
  flex: 1;
  max-width: 1440px;
  justify-content: space-around;
  align-items: flex-start;

  .logo-link {
    display: flex;
    ${props => props.reduzir && `padding: 30px 0`};
    ${props =>
      props.reduzir ? `align-items: center` : `align-items: flex-end`};

    span {
      font-size: 18px;
      font-weight: 600;
      line-height: 19px;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      ${props => (props.reduzir ? `padding: 0 20px 4px 20px` : `padding:20px`)};
      ${props => (props.reduzir ? `color: #2AD94B` : `color:#a0a0a0`)};
      transition: all 400ms ease;
    }
  }

  @media only screen and (max-width: 980px) {
    .logo-link {
      padding: 30px 0;
      align-items: center;
      span {
        color: #2ad94b;
        padding: 0 20px 4px 20px;
      }
    }
  }
`;
export const Logo = styled.div`
  ${props =>
    props.reduzir
      ? `background: rgba(13,78,50, 0)`
      : `background: rgba(13,78,50, 1)`};
  ${props => (props.reduzir ? `width: auto` : `width: 120px`)};
  ${props => (props.reduzir ? `height: auto` : `height: 160px`)};
  ${props => (props.reduzir ? `align-items: center` : `align-items: flex-end`)};
  ${props => (props.reduzir ? `padding: 0` : `padding: 20px`)};

  display: flex;
  justify-content: center;
  transition: all 400ms ease;

  @media only screen and (max-width: 980px) {
    background: rgba(13, 78, 50, 0);
    width: auto;
    height: auto;
    align-items: center;
    padding: 0;
  }
`;
export const Menu = styled.nav`
  display: flex;
  padding: 30px 0;

  a {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    padding: 2px 16px;
    transition: color 600ms ease;
    position: relative;
    ${props => (props.color ? `color:${props.color}` : `color:#1e2623`)}
    ${props => props.reduzir && `color: #FFF`};

    &.vem {
      ${props =>
        props.colorVem ? `color:${props.colorVem}` : `color: #0f5842`};
      ${props => props.reduzir && `color:#2AD94B`};
    }

    &:hover {
      color: #2ad94b;
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -5px;
      border-radius: 100%;
      background: #2ad94b;
      margin: auto;
      display: block;
      width: 7px;
      transition: all 400ms ease;
      height: 7px;
      opacity: 0;
      transform: scale(0);
    }
  }
  @media only screen and (max-width: 980px) {
    a {
      display: none;
    }
  }
`;
export const ButtonMenu = styled.button`
  width: 32px;
  height: 32px;
  background: url(${iconMenu}) no-repeat center center;
  display: none;
  transition: all 400ms ease;
  position: relative;

  @media only screen and (max-width: 980px) {
    display: flex;
  }
`;

export const Submenu = styled.ul`
  width: 260px;
  height: 100%;
  display: ${props => (props.open ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  right: 0;
  top: 0;
  list-style: none;
  background-color: #1e2623;
  padding: 24px;
  z-index: 99;
  box-shadow: -3px 0 20px rgba(0, 0, 0, 0.25);
  transition: all 400ms ease;
  overflow: auto;

  li {
    padding: 8px 0;

    a {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 16px;
      padding: 2px 16px;
      transition: color 600ms ease;
      position: relative;
      color: #fff;

      &.vem {
        color: #2ad94b;
      }

      &:hover {
        color: #2ad94b;
        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
        border-radius: 100%;
        background: #2ad94b;
        margin: auto;
        display: block;
        width: 7px;
        transition: all 400ms ease;
        height: 7px;
        opacity: 0;
        transform: scale(0);
      }
    }
  }
`;
