import React, { useEffect } from "react";

import WOW from "wowjs";

import Header from "../../components/Header";
import { Container, Content } from "./styles";
import Banners from "../../components/Banner";
import Jobs from "../../components/Jobs";
import Recognition from "../../components/Recognition";
import Instagram from "../../components/Instagram";
import Footer from "../../components/Footer";

export default function Main() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <Container>
      <Header color={"#1e2623"} colorVem={"#FFF"} />
      <Content>
        <Banners />
        <Jobs more />
        <Recognition />
        {/* <Instagram /> */}
        <Footer />
      </Content>
    </Container>
  );
}
