import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import {
  Container,
  Content,
  JobHeader,
  ContentHeader,
  ContentJob,
  Center,
  Wrap
} from "./styles";

import bgHeader from "../../../../assets/works/sidernatal/bg-header.png";
import camisa from "../../../../assets/works/sidernatal/camisa.png";
import logo from "../../../../assets/works/sidernatal/logo.png";
import peca from "../../../../assets/works/sidernatal/pecas-1.png";
import totem from "../../../../assets/works/sidernatal/totem.png";

import WOW from "wowjs";

export default class SiderNatal extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <Container>
        <Header color={"#FFF"} colorVem={"#3ADE59"} />
        <Content>
          <JobHeader bg={bgHeader}>
            <ContentHeader>
              <h1 className="wow fadeInDown" data-wow-delay="100ms">
                Sider <br />
                Shopping
              </h1>
            </ContentHeader>
          </JobHeader>
          <ContentJob>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="150ms">
                Campanha de Natal 2018
              </h2>
              <Wrap className="wow fadeIn" data-wow-delay="200ms">
                <p>
                  Na época mais inspiradora do ano, a alegria invade o Sider
                  Shopping, trazendo toda a felicidade do universo circense para
                  decorar o mall. Uma incrível parada natalina é feita para
                  marcar a chegada do bom velhinho. No VT de 30’’, a magia fica
                  muito clara, com a expectativa dos personagens pela chegada de
                  Papai Noel.
                </p>
                <Link to="/trabalhos">
                  <i className="fa fa-long-arrow-left" aria-hidden="true" />
                  Voltar
                </Link>
              </Wrap>
              <div className="video wow fadeIn" data-wow-delay="200ms">
                <iframe
                  src="https://player.vimeo.com/video/254001318?loop=1&color=ffffff&title=0&byline=0&portrait=0"
                  className="iFrame"
                  frameborder="0"
                  allow="autoplay; fullscreen"
                  allowfullscreen
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />

              <h3 className="wow fadeIn solo" data-wow-delay="150ms">
                Peças
              </h3>
            </Center>
            <img
              src={peca}
              alt="Mascote Orthopride"
              className="full wow fadeIn"
              data-wow-delay="200ms"
            />
            <Center>
              <h3 className="wow fadeIn solo" data-wow-delay="150ms">
                Identidade Visual
              </h3>
            </Center>
            <img
              src={logo}
              alt="Mascote Orthopride"
              className="full wow fadeIn"
              data-wow-delay="200ms"
            />
            <Center>
              <h3 className="wow fadeIn solo" data-wow-delay="150ms">
                Camisa e display
              </h3>
            </Center>
            <Center>
              <Wrap>
                <img
                  src={camisa}
                  alt="Cartaz Pedalada"
                  className="wow fadeIn"
                  data-wow-delay="150ms"
                />
                <img
                  src={totem}
                  alt="Cartaz Pedalada"
                  className="wow fadeIn"
                  data-wow-delay="200ms"
                />
              </Wrap>
            </Center>
          </ContentJob>
          <Footer />
        </Content>
      </Container>
    );
  }
}
