import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import {
  Container,
  Content,
  JobHeader,
  ContentHeader,
  ContentJob,
  Center,
  Wrap
} from "./styles";

import ElementHeader from "../../../../assets/works/seleto/elements-header.png";
import Notebook from "../../../../assets/works/seleto/note-site.png";
import SiteNovo from "../../../../assets/works/seleto/home-site.png";

import WOW from "wowjs";

export default class Seleto extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <Container>
        <Header color={"#FFF"} colorVem={"#FFF"} />
        <Content>
          <JobHeader>
            <ContentHeader>
              <img
                src={ElementHeader}
                alt="Graph"
                className="element wow fadeIn"
                data-wow-delay="200ms"
              />
              <h1>Seleto Hotel</h1>
              <img
                src={Notebook}
                alt="Novo Site"
                className="notebook wow pulse"
                data-wow-delay="200ms"
              />
            </ContentHeader>
          </JobHeader>
          <ContentJob>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="150ms">
                Novo Site
              </h2>
              <Wrap className="wow fadeIn" data-wow-delay="200ms">
                <p>
                  Um hotel incrível no principal centro comercial de Volta
                  Redonda, merecia um site a altura. O projeto foi todo pensado
                  para mostrar toda a modernidade, e flexbilidade dos serviços
                  do Hotel.
                </p>
                <Link to="/trabalhos">
                  <i className="fa fa-long-arrow-left" aria-hidden="true" />
                  Voltar
                </Link>
              </Wrap>
            </Center>
          </ContentJob>
          <ContentJob gray>
            <img
              src={SiteNovo}
              alt="Novo Site"
              className="wow fadeIn"
              data-wow-delay="150ms"
            />
          </ContentJob>
          <Footer />
        </Content>
      </Container>
    );
  }
}
