import React from "react";
import { Link } from "react-router-dom";

import { Container, Content, Card } from "./styles";
import cardFrilat from "../../assets/works/thumb/card-frilat.png";
import cardOrtho from "../../assets/works/thumb/card-orthopride.png";
import cardSeleto from "../../assets/works/thumb/card-seleto.png";
import cardSider from "../../assets/works/thumb/card-sider.png";
import cardSoul from "../../assets/works/thumb/card-soul.png";
import cardPintado from "../../assets/works/thumb/card-toca-pintado.png";
import cardUnimed30 from "../../assets/works/thumb/card-unimed-30anos.png";
import cardUnimed from "../../assets/works/thumb/card-unimed.png";
import cardUimed2 from "../../assets/works/thumb/card-unimed2.png";
import cardAppOrtho from "../../assets/works/thumb/card-ortho-app.png";
import cardWebCol from "../../assets/works/thumb/card-webcol.png";
import cardInterligados from "../../assets/works/thumb/card-interligados.png";
import cardCorteva from "../../assets/works/thumb/card-corteva.png";

import Masonry from "react-masonry-component";

export default function Jobs({ more }) {
  return (
    <Container>
      <Content>
        <Masonry
          className={"grid"}
          options={{ transitionDuration: 0 }}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
        >
          {more && (
            <Card
              className="card-introduction card wow fadeIn"
              data-wow-delay="100ms"
            >
              <h2 className="title">
                Temos pressa, somos interativos e buscamos resultados.
              </h2>
              <p>
                A Foco nasceu em 2001 com o objetivo de crescer junto com o
                mundo digital que surgia diante de nós. Crescemos e nos tornamos
                uma agência diferente de como você conhece. Nascemos conectados.
              </p>
              <Link to="/agencia" className="sobre">
                Sobre Nós
              </Link>
            </Card>
          )}
          <Card className="card wow fadeIn" data-wow-delay="100ms">
            <Link to="/trabalhos/orthopride" className="link">
              <img src={cardOrtho} alt="Orthopride" className="figure" />
              <span className="legend">Orthopride - Campanha</span>
            </Link>
          </Card>
          <Card className="card wow fadeIn" data-wow-delay="150ms">
            <Link to="/trabalhos/unimed-30-anos" className="link">
              <img src={cardUnimed30} alt="Unimed" className="figure" />
              <span className="legend">Unimed - 30 Anos</span>
            </Link>
          </Card>

          <Card className="card wow fadeIn" data-wow-delay="250ms">
            <Link to="/trabalhos/orthopride-app" className="link">
              <img src={cardAppOrtho} alt="Orthopride App" className="figure" />
              <span className="legend">Orthopride - Aplicativo</span>
            </Link>
          </Card>
          <Card className="card wow fadeIn" data-wow-delay="250ms">
            <Link to="/trabalhos/sider-shopping-natal" className="link">
              <img src={cardSider} alt="Sider Shopping" className="figure" />
              <span className="legend">Sider Shopping - Campanha</span>
            </Link>
          </Card>
          <Card className="card wow fadeIn" data-wow-delay="300ms">
            <Link to="/trabalhos/soul" className="link">
              <img src={cardSoul} alt="Soul" className="figure" />
              <span className="legend">Soul - Marca</span>
            </Link>
          </Card>
          <Card className="card wow fadeIn" data-wow-delay="200ms">
            <Link to="/trabalhos/business-intelligence" className="link">
              <img src={cardCorteva} alt="App BI" className="figure" />
              <span className="legend">Gera Sinergia - App BI</span>
            </Link>
          </Card>
          <Card className="card wow fadeIn" data-wow-delay="200ms">
            <Link to="/trabalhos/unimed-pedalada" className="link">
              <img src={cardUnimed} alt="Unimed" className="figure" />
              <span className="legend">Unimed - Pedalada Costazul</span>
            </Link>
          </Card>

          {!more && (
            <>
              <Card className="card wow fadeIn" data-wow-delay="200ms">
                <Link to="/trabalhos/webcol" className="link">
                  <img src={cardWebCol} alt="WebCol" className="figure" />
                  <span className="legend">WebCol - Applicação Web</span>
                </Link>
              </Card>
              <Card className="card wow fadeIn" data-wow-delay="200ms">
                <Link to="/trabalhos/frilat" className="link">
                  <img src={cardFrilat} alt="Frilat " className="figure" />
                  <span className="legend">Frilat - Redes Sociais</span>
                </Link>
              </Card>
              <Card className="card wow fadeIn" data-wow-delay="100ms">
                <Link to="/trabalhos/toca-do-pintado" className="link">
                  <img
                    src={cardPintado}
                    alt="Toca do Pintado"
                    className="figure"
                  />
                  <span className="legend">
                    Toca do Pintado - Redes Sociais
                  </span>
                </Link>
              </Card>
              <Card className="card wow fadeIn" data-wow-delay="200ms">
                <Link to="/trabalhos/seleto" className="link">
                  <img src={cardSeleto} alt="Seleto hotel" className="figure" />
                  <span className="legend">Seleto - Novo Site</span>
                </Link>
              </Card>
              <Card className="card wow fadeIn" data-wow-delay="150ms">
                <Link to="/trabalhos/interligados" className="link">
                  <img src={cardInterligados} alt="Unimed" className="figure" />
                  <span className="legend">Unimed - Game</span>
                </Link>
              </Card>
              <Card className="card wow fadeIn" data-wow-delay="150ms">
                <Link to="/trabalhos/unimed" className="link">
                  <img src={cardUimed2} alt="Unimed" className="figure" />
                  <span className="legend">Unimed</span>
                </Link>
              </Card>
            </>
          )}
        </Masonry>
        {more && (
          <Link to="/trabalhos" className="moreWorks">
            Veja nossos trabalhos
          </Link>
        )}
      </Content>
    </Container>
  );
}
