import styled from "styled-components";
import BgContact from "../../assets/images/bg-contact.png";
import Polygon from "../../assets/images/polygon-header.png";
import iconPin from "../../assets/images/pin.svg";

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InnerBanner = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: black;
  overflow: hidden;
  height: 555px;
  color: #fff;
  background-image: url(${BgContact});
  position: relative;

  img.el {
    position: absolute;
    left: -2%;
    top: 10%;
    z-index: 98;
  }
`;

export const ContentBanner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  width: 980px;
  margin: 0 auto;

  @media screen and (max-width: 980px) {
    width: 80%;
  }
`;

export const Title = styled.h1`
  @keyframes typing {
    from {
      max-width: 0;
    }
    to {
      max-width: 100%;
    }
  }
  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: #2ad94b;
    }
  }
  font-size: 80px;
  line-height: 100px;
  font-weight: 600;
  letter-spacing: -3px;
  animation: typing 2s steps(40, end), blink-caret 1s step-end infinite;
  border-right: 4px solid;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;

  @media screen and (max-width: 640px) {
    font-size: 72px;
  }
`;

export const Subtitle = styled.section`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 30px 150px;
  background: #272727;
  position: relative;
  text-align: center;

  &:before {
    width: 100%;
    max-width: 1440px;
    height: 152px;
    background: url(${Polygon}) no-repeat left top;
    background-size: cover;
    position: absolute;
    top: -152px;
    z-index: 97;
    content: "";
    left: center;
  }

  h2 {
    color: #fff;
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 32px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #a7a7a7;
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    border: 0;
    h1 {
      width: 100%;
    }
  }
`;

export const Center = styled.div`
  max-width: 440px;
  margin: 0 auto;
  position: relative;
  width: 100%;
`;

export const WrapForm = styled.section`
  max-width: 740px;
  margin: -100px auto 0 auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 50px;
  margin-bottom: 60px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 980px) {
    width: 100%;
    max-width: 100%;
  }

  &:before {
    width: 100%;

    display: block;
    content: "";
    width: 100%;
    height: 6px;
    position: absolute;
    left: 0;
    top: 0;
    background: rgb(171, 217, 66);
    background: linear-gradient(
      270deg,
      rgba(171, 217, 66, 1) 0%,
      rgba(47, 145, 116, 1) 100%
    );
  }
  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 54px;
    text-align: center;
    color: #555555;
    margin-top: 20px;
  }

  form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    div.wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      position: relative;

      span {
        position: absolute;
        top: 59%;
        right: 5%;

        i {
          font-size: 16px;
          color: #ff4b2b;
        }
      }

      @media screen and (max-width: 980px) {
        width: 100%;
      }

      &.full {
        width: 100%;
      }
    }

    label {
      display: block;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 15px;
      color: #272727;
      margin-bottom: 8px;
    }

    input {
      width: 100%;
      min-width: 300px;
      height: 52px;
      border: 1px #aaaaaa solid;
      padding: 16px 40px 16px 16px;
      font-size: 16px;

      @media screen and (max-width: 980px) {
        min-width: 100%;
      }
    }

    textarea {
      width: 100%;
      height: 110px;
      border: 1px #aaaaaa solid;
      padding: 16px;
      font-size: 16px;
    }

    div.center {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        background: #0d4e3b;
        padding: 16px 32px;
        font-weight: 600;
        font-size: 16px;
        color: #fff;
        min-width: 214px;

        &:hover {
          background: #001c09;
        }
      }
    }
  }
`;

export const Location = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  text-align: center;
  align-items: center;
  margin-bottom: 96px;

  h3 {
    font-size: 27px;
    line-height: 27px;
    color: #272727;
    margin-bottom: 24px;
    text-align: center;
  }

  p {
    font-size: 16px;
    color: #555555;
    line-height: 24px;
    margin-bottom: 32px;
  }
`;

export const Map = styled.div`
  width: 100%;
  max-width: 740px;
  border: 1px #d2d2d2 solid;
`;

export const Pin = styled.div`
  display: block;
  width: 40px;
  height: 45px;
  background: url(${iconPin}) no-repeat center center;
`;
