import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import {
  Container,
  Content,
  JobHeader,
  ContentHeader,
  ContentJob,
  Center,
  Wrap
} from "./styles";

import bgHeader from "../../../../assets/works/unimed/bg-header.png";
import cartaz1 from "../../../../assets/works/unimed/cartaz1.png";
import cartaz2 from "../../../../assets/works/unimed/cartaz2.png";

import WOW from "wowjs";

export default class Unimed extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <Container>
        <Header color={"#FFF"} colorVem={"#3ADE59"} />
        <Content>
          <JobHeader bg={bgHeader}>
            <ContentHeader>
              <h1 className="wow fadeInDown" data-wow-delay="100ms">
                Unimed <br />
                Volta Redonda
              </h1>
            </ContentHeader>
          </JobHeader>
          <ContentJob>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="150ms">
                Campanha de Fim de Ano
              </h2>
              <Wrap className="wow fadeIn" data-wow-delay="200ms">
                <p>
                  Com a proximidade do fim do ano, sempre nos colocamos a pensar
                  sobre mudanças e planos para o novo ciclo. E foi com este
                  mote, que a Unimed Volta Redonda lança a campanha de incentivo
                  de vendas. Foram feitos cards para redes sociais, gifs, e-mail
                  marketing e anúncio de jornal. Independente da época do ano, a
                  Unimed sempre tem um plano para você.
                </p>
                <Link to="/trabalhos">
                  <i className="fa fa-long-arrow-left" aria-hidden="true" />
                  Voltar
                </Link>
              </Wrap>
              <h3 className="wow fadeIn solo" data-wow-delay="150ms">
                Anúncio
              </h3>
            </Center>

            <Center>
              <Wrap>
                <img
                  src={cartaz1}
                  alt="Cartaz Pedalada"
                  className="wow fadeIn"
                  data-wow-delay="150ms"
                />
                <img
                  src={cartaz2}
                  alt="Cartaz Pedalada"
                  className="wow fadeIn"
                  data-wow-delay="200ms"
                />
              </Wrap>
            </Center>
          </ContentJob>
          <Footer />
        </Content>
      </Container>
    );
  }
}
